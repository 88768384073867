/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useEffect, useState } from 'react';
import style from './style';
import utils from '../../utils';
import cmsUtils from '../../cms/utils/cmsUtils';
import { usePost, SiteLink, Loading, ErrorPopup } from '../../components';
import { useForm } from '../../form';
import env from '../../env';
//import { FaSpinner } from "react-icons/fa";
//import Slider from "react-slick";
import { getNewsFeedSearchModel } from './newsFeedSearchModel';

export function NewsFeed(props) {

  const item = props.item;
  const cmsOption = props.cmsOption || {};

  const newsCategory = "1";
  const numberToshow = cmsUtils.payload(item, "NumberToshow");
  const useSlider = cmsUtils.payload(item, 'UseSlider') === 'slider';
  const tileType = "type" + (cmsUtils.payload(item, 'TileType') || "1");
  const topic = cmsUtils.payload(item, 'Topic') || '';

  const model = getNewsFeedSearchModel({ topic: '' });
  const form = useForm(model, { usePlaceholder: true, onControlChanged: orderItemChanged });
  const render = (name) => form.renderControl(name, null);

  const cssClass = utils.classNames('cms_item', 'newsFeed', (useSlider && tileType === 'type2') && 'useSlider', item.cssClass || item.anchorName || '');
  const post = usePost();

  function orderItemChanged(e, control) {
    if (control && control.name === 'topic') {
      const _value = { id: newsCategory, top: numberToshow, topic: control.value };
      post.send(env.apiBase + "/api/proxy/getnewslasttop", _value);
    }
  }

  useEffect(() => {

    if (!cmsOption.isCmsEdit) {
      const _value = { id: newsCategory, top: numberToshow, topic: topic };
      post.send(env.apiBase + "/api/proxy/getnewslasttop", _value);
    }

    // eslint-disable-next-line
  }, []);

  // if (post.done() && sendingStatus === 'pending'){
  // }

  const data = post.response;

  if (cmsOption.isCmsEdit) {
    return (
      <div className={cssClass}
        data-cms-item-id={item.itemId}
        id={item.anchorName}>
        <pre style={{ fontSize: '12px', margin: '5px', padding: '10px', background: '#eee', border: '1px solid #ccc' }}>
          <b> News Feed Item </b>
        </pre>
      </div>
    )
  }

  if (post.loading()) {
    return <Loading />
  }
  else if (post.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={post.errors} />
  }

  return (

    <Fragment>

      {
        topic === '' &&
        <div className='row news__select__container' css={style.news__select__container}>
          <div className='col-12 col-md-8' css={style.title__text}> LATEST NEWS </div>
          <div className='col-12 col-md-4 news__select' css={style.news__select}>
            {render("topic")}
          </div>
        </div>
      }

      {
        (data && data.results && data.results.Result.length) ?
          <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName} css={(useSlider && tileType === 'type2') ? style.slider : style.pageTiles}>
            {
              data.results.Result.map((item, index) => <Item newsItem={item} tileType={tileType} useSlider={useSlider} key={index} />)
            }
          </div> :
          <div> No Data </div>
      }

    </Fragment>

    // <Fragment>
    //   <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName} css={(useSlider && tileType === 'type2') ? style.slider : style.pageTiles}>
    //     {(data && data.results) ?
    //       (
    //         useSlider && tileType === 'type2' ?
    //           <Slider {...settings} >
    //             {
    //               data.results.Result.map((item, index) => <Item newsItem={item} tileType={tileType} useSlider={useSlider} key={index} />)
    //             }
    //           </Slider> :
    //           data.results.Result.map((item, index) => <Item newsItem={item} tileType={tileType} useSlider={useSlider} key={index} />)
    //       ) :

    //       <div css={style.newsFeed_loading} className="newsFeed_loading">
    //         <FaSpinner className="spinner_loading"></FaSpinner>
    //       </div>
    //     }
    //   </div>
    // </Fragment>
  )
}

function Item(props) {

  const newsItem = props.newsItem;
  const tileType = props.tileType;
  const useSlider = props.useSlider;
  const _newsTitleTopic = newsItem.NewsTitle.split("||");
  const newsTitle = _newsTitleTopic[0];
  const topic = _newsTitleTopic.length === 2 ? _newsTitleTopic[1] : "Club Events";

  console.log("newsItem.NewsPhotoURL", newsItem.NewsPhotoURL);

  return (
    <div css={style.pageTile__tile} className={`pageTile__sharkCage ${tileType} ${useSlider && 'useSlider'}`}>
      <SiteLink className="pageTile__sharkCage__content" css={style.tile__content} to={`/newsitem/${newsItem.NewsId}`}>
        <div className="pageTile__sharkCage__bg__wrapper" css={style.tile__bg__wrapper}>
          <div className="pageTile__sharkCage__bg" css={style.tile__bg} style={{ backgroundImage: utils.css.bgUrlStyle((newsItem.NewsPhotoURL && newsItem.NewsPhotoURL !== '' ? newsItem.NewsPhotoURL : "/-/nafc/Common/Imgs/logo/default-news.jpg")) }}></div>
          {/* <div className="pageTile__sharkCage__bg" css={style.tile__bg} style={{ backgroundImage: utils.css.bgUrlStyle("https://dummyimage.com/640x360/333333/fff") }}></div> */}
        </div>
        <div className="pageTile__sharkCage__details" css={style.tile__details}>
          <div className="info-wrapper" css={style.info__wrapper}>

            <div className="info-topic" css={style.info__topic}>
              {topic}
            </div>

            <div className="info-title" css={style.info__title}>
              {newsTitle}
            </div>

            <div className="info-des" css={style.info__des}>
              <div className="infoDesc" css={style.infoDesc}>
                {newsItem.NewsDescription}
              </div>
            </div>

            <GetNewsDate newsDateData={newsItem.NewsDate} />

          </div>
        </div>
      </SiteLink>
    </div>
  )
}

function GetNewsDate({ newsDateData }) {




  const [newsDate, setNewsDate] = useState();

  useEffect(() => {
    const _newsDate = new Date(newsDateData);
    let resultDate = "";

    switch (_newsDate.getMonth()) {
      case 0:
        resultDate = "JANUARY";
        break;
      case 1:
        resultDate = "FEBRUARY";
        break;
      case 2:
        resultDate = "MARCH";
        break;
      case 3:
        resultDate = "APRIL";
        break;
      case 4:
        resultDate = "MAY";
        break;
      case 5:
        resultDate = "JUNE";
        break;
      case 6:
        resultDate = "JULY";
        break;
      case 7:
        resultDate = "AUGUST";
        break;
      case 8:
        resultDate = "SEPTEMBER";
        break;
      case 9:
        resultDate = "OCTOBER";
        break;
      case 10:
        resultDate = "NOVEMBER";
        break;
      case 11:
        resultDate = "DECEMBER";
        break;
      default:
        break;
    }

    resultDate = resultDate + ' ' + _newsDate.getDate() + ', ' + _newsDate.getFullYear();
    setNewsDate(resultDate);

    // eslint-disable-next-line
  }, []);

  return (
    <div className="info-date" css={style.info__date}>
      {newsDate}
    </div>
  )
}
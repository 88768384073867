import { css  } from '@emotion/core'
import { mq, variables } from '../../cssInJs'

export default {
  top: css(mq({
    //height: [44, null, 80],
    height: [44, null, 56],
    background: variables.blueColor,
    position: 'fixed',
    width: '100%',
    zIndex: '999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s'
  })),
  topScrolled: css(mq({
    height: [44, null, 56],
    marginTop:'0rem'
  })),
  logo: css(mq({
  })),
  logoImg: css(mq({
    display: 'block',
    height: [150, null, 260],
    marginTop: ['3.5rem', null, '4.5rem'],
    transition: 'all 0.3s'
  })),
  logoScrolled: css(mq({
    //height: [73, null, 90],
    //marginTop: ['-12px', null, '-1px']
    img : css(mq({
      height: [85, null, 160],
      marginTop: ['2rem', null, '2.5rem'],
    }))
  })),

  logo2Img : mq({
    position:'absolute',
    //top : ['-2px'],
    height:['33px', null, '43px'],
    left: ['3rem', null, '3.5rem']
  })
  
}
import { mq, variables } from '../../../../cssInJs';

export default{
    
    latest__news__banner__main__container : {
        '.slick-dots' : {
            bottom:'25px'
        },
        '.slick-dots li.slick-active button:before':{
            color:'white',
            opacity: 1
        },
        '.slick-dots li button:before' : {
            color:'grey',
            opacity: 0.8
        }
    },

    latest__news__banner__gradient : {
        position: 'absolute',
        width:'100%',
        height:'100%',
        //background-image: linear-gradient(red, yellow);
        backgroundImage: 'linear-gradient(rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0.8) 100%)'
        //rgba(255,0,0,0), rgba(255,0,0,1)
    },
    
    latest__news__banner__bg : mq({
        // '&::before' : {
        //     background:'rgba(0,0,0,0.3333)',
        //     width:'100%',
        //     height:'100%',
        //     position:'absolute',
        //     zIndex: 1,
        //     content: '""'
        // }
    }),
    
    latest__news__banner__container : mq({
        position: 'absolute',
        left: '50%',
        bottom: '10%',
        transform: 'translateX(-50%)',
        zIndex: 2,
        maxWidth: ['unset','510px','686px','932px','1100px',null],
        width:['94%',null,'100%'],
        //border: '1px solid red'
    }),
    latest__news__banner__title : mq({
        color: 'white',
        fontWeight: 700,
        fontSize: ['24px', null, '48px'],
        fontFamily: variables.familyHeader
    }),
    latest__news__banner__topic : {
        color: variables.redColor,
        padding: '0.2rem 0.5rem',
        display: 'inline-block',
        backgroundColor: 'white',
        fontWeight: 900
    },
    latest__news__banner__date : {
        color:'white'
    }
}
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Fragment, useEffect, useState } from "react";
import utils from "../../utils";
import cmsUtils from "../../cms/utils/cmsUtils";
//import { Button } from '../../components/';
import env from "../../env";
import style from "./style";
import { PlayerPopUpView } from "../playerPopUpView/view";

export function PlayerProfiles(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames(
    "cms_item",
    "player__profiles",
    item.cssClass || item.anchorName || ""
  );
  const playerGroup = cmsUtils.payload(item, "PlayerGroup") || "senior";
  const [playerResult, setPlayerResult] = useState();

  //const [trigger, setTrigger] = useState("");
  const [openStatus, setOpenStatus] = useState({});
  const [openPopup, setOpenPopup] = useState(false);
  const [popupItem, setPopupItem] = useState();

  // useEffect(() => {

  //   if (cmsOption.isCmsEdit){
  //     return;
  //   }

  //   if(trigger === ""){
  //     return;
  //   }

  //   const fetchData = async () => {
  //     const playerGroup = trigger;
  //     const res = await fetch(env.apiBase + `/api/footy/getplayers/${playerGroup}`);
  //     const data = await res.json();

  //     console.log('data', data);
  //     setPlayerResult({...playerResult, [playerGroup] : data.results.Result});
  //     setOpenStatus({...openStatus, [playerGroup] : true});

  //   }

  //   //get.send(env.apiBase + '/api/footy/getsearchfilter');
  //   fetchData();
  //   setTrigger("");

  //   // eslint-disable-next-line
  // }, [trigger]);

  useEffect(() => {
    if (cmsOption.isCmsEdit) {
      return;
    }

    // if(trigger === ""){
    //   return;
    // }

    const fetchData = async () => {
      // const playerGroup = trigger;
      const res = await fetch(
        env.apiBase + `/api/footy/getplayers/${playerGroup}`
      );
      const data = await res.json();
      
      setPlayerResult({ ...playerResult, [playerGroup]: data.results.Result });
      setOpenStatus({ ...openStatus, [playerGroup]: true });
    };

    //get.send(env.apiBase + '/api/footy/getsearchfilter');
    fetchData();
    //setTrigger("");

    // eslint-disable-next-line
  }, []);

  if (cmsOption.isCmsEdit) {
    return (
      <div
        className={cssClass}
        data-cms-item-id={item.itemId}
        id={item.anchorName}
      >
        <pre
          style={{
            fontSize: "12px",
            margin: "5px",
            padding: "10px",
            background: "#eee",
            border: "1px solid #ccc",
          }}
        >
          <b> PlayerProfiles </b>
        </pre>
      </div>
    );
  }

  // if (get.loading()) {
  //   return <Loading />
  // } else if (get.hasErrors()) {
  //   return <ErrorPopup title="Loading Error" errors={get.errors} />
  // }

  // const data = get.response;

  return (
    <Fragment>
      {openPopup && (
        <PlayerPopUpView
          popupItem={popupItem}
          setOpenPopup={setOpenPopup}
        ></PlayerPopUpView>
      )}
      <div
        css={style.player__profiles}
        className={cssClass}
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
      >
        {(playerGroup === "" || playerGroup === "senior") && (
          <div style={{ textAlign: "center" }}>
            {playerResult &&
              playerResult.senior &&
              openStatus.senior === true && (
                <div style={{ marginTop: "1rem" }}>
                  <div css={style.players}>
                    {playerResult.senior.map((item, index) => (
                      <Player
                        key={index}
                        item={item}
                        setOpenPopup={setOpenPopup}
                        setPopupItem={setPopupItem}
                        index={index}
                      ></Player>
                    ))}
                  </div>
                </div>
              )}
          </div>
        )}

        {(playerGroup === "" || playerGroup === "women") && (
          <div style={{ textAlign: "center" }}>
            {playerResult &&
              playerResult.women &&
              openStatus.women === true && (
                <div style={{ marginTop: "1rem" }}>
                  <div css={style.players}>
                    {playerResult.women.map((item, index) => (
                      <Player
                        key={index}
                        item={item}
                        setOpenPopup={setOpenPopup}
                        setPopupItem={setPopupItem}
                        index={index}
                      ></Player>
                    ))}
                  </div>
                </div>
              )}
          </div>
        )}

        {(playerGroup === "" || playerGroup === "wheelchair") && (
          <div style={{ textAlign: "center" }}>
            {playerResult &&
              playerResult.wheelchair &&
              openStatus.wheelchair === true && (
                <div style={{ marginTop: "1rem" }}>
                  <div css={style.players}>
                    {playerResult.wheelchair.map((item, index) => (
                      <Player
                        key={index}
                        item={item}
                        setOpenPopup={setOpenPopup}
                        setPopupItem={setPopupItem}
                        index={index}
                      ></Player>
                    ))}
                  </div>
                </div>
              )}
          </div>
        )}
      </div>
    </Fragment>
  );
}

function Player(props) {
  const item = props.item;

  return (
    <div
      css={style.player}
      onClick={() => {
        props.setPopupItem(item);
        props.setOpenPopup(true);
        document.body.style.overflow = "hidden";
      }}
    >
      <img
        src={
          item.thumbImageUrl && item.thumbImageUrl !== ""
            ? item.thumbImageUrl
            : "/core/tipping/imgs/teamicon/roosters.png"
        }
        alt={"image" + props.index}
      />
      <div className="jumper__text"> {item.jumperNoText} </div>
      <div className="name__text"> {item.playerName} </div>
    </div>
  );
}

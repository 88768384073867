const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const colors = {
  blueColor: '#072e9d',
  darkGrayColor: '#495057',
  grayColor:'#edeef2',
  redColor : '#DE1F26'
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"stratum-1-web",serif',

  primaryColor: '#c20f2f',
  fontColor: colors.darkGrayColor,
  bgColor: colors.grayColor,

  ...colors,
  ...overloading
}

export default variables;
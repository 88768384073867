/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import utils from 'utils';
import { useGet, SiteLink } from '../../../../components';
import env from '../../../../env';
import Slider from "react-slick";
import style from "./latestNewsBannerStyle";

export function LatestNewsBanner(props) {
    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'bannerPanel', `bannerWrapperSize--standard`, item.cssClass || item.anchorName || '');
    const get = useGet();
    const [banners, setBanners] = useState();

    useEffect(() => {
        get.send(env.apiBase + `/api/news/getlatestnewsitem`);
        // eslint-disable-next-line
    }, []);

    if (get.done() && !banners) {
        const _dataResulst = get.response;
        setBanners(_dataResulst);
    }

    return (
        <div className={cssClass} css={style.latest__news__banner__main__container} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
            {
                banners &&
                <GetNewsBanners banners={banners} />
            }
        </div>
    )
}

function GetNewsBanners({ banners }) {
    var settings = {
        dots: true,
        infinite: true,
        speed: 1500,
        autoplaySpeed: 3000,
        fade: true,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <Slider {...settings} >
            {banners.map((item, index) => <GetNewsBanner key={index} banner={item} />)}
        </Slider>
    )
}

function GetNewsBanner({ banner }) {
    const cssClass = utils.classNames('cms_item', 'banner-item', 'bannerSize');
    const newsPhotoURL = banner.newsPhotoURL;
    const _newsTitleTopic = banner.newsTitle.split("||");
    const newsTitle = _newsTitleTopic[0];
    const topic = _newsTitleTopic.length === 2 ? _newsTitleTopic[1] : "Club Events";
    const newsDate = banner.newsDate;

    //"https://nafc.com.au" +
    return (
        <SiteLink to={`/newsitem/${banner.newsId}`}>
            <div className={cssClass} css={style.latest__news__banner__bg} style={{ backgroundImage: utils.css.bgUrlStyle(newsPhotoURL) }}>
                <div className='latest__news__banner__gradient' css={style.latest__news__banner__gradient}></div>
                <div className='latest__news__banner__container' css={style.latest__news__banner__container}>
                    {
                        topic && topic !== "" &&
                        <div className='latest__news__banner__topic' css={style.latest__news__banner__topic}>
                            {topic}
                        </div>
                    }
                    <div className='latest__news__banner__title' css={style.latest__news__banner__title}>
                        {newsTitle}
                    </div>
                    <GetNewsDate newsDateData={newsDate} />
                </div>
            </div>
        </SiteLink>
    )
}

function GetNewsDate({ newsDateData }) {
    //const newsDate = new Date(newsDateData);
    const [newsDate, setNewsDate] = useState();

    useEffect(() => {
        const _newsDate = new Date(newsDateData);
        let resultDate = "";

        switch (_newsDate.getMonth()) {
            case 0:
                resultDate = "JANUARY";
                break;
            case 1:
                resultDate = "FEBRUARY";
                break;
            case 2:
                resultDate = "MARCH";
                break;
            case 3:
                resultDate = "APRIL";
                break;
            case 4:
                resultDate = "MAY";
                break;
            case 5:
                resultDate = "JUNE";
                break;
            case 6:
                resultDate = "JULY";
                break;
            case 7:
                resultDate = "AUGUST";
                break;
            case 8:
                resultDate = "SEPTEMBER";
                break;
            case 9:
                resultDate = "OCTOBER";
                break;
            case 10:
                resultDate = "NOVEMBER";
                break;
            case 11:
                resultDate = "DECEMBER";
                break;
            default:
                break;
        }

        resultDate = resultDate + ' ' + _newsDate.getDate() + ', ' + _newsDate.getFullYear();
        setNewsDate(resultDate);

        // eslint-disable-next-line
    }, []);

    return (
        <div className='latest__news__banner__date' css={style.latest__news__banner__date}>
            {newsDate}
        </div>
    )
}
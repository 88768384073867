import { mq, variables } from '../../cssInJs'

// export default {  
//   container : {
//     //marginTop: '0.5rem',
//     //marginBottom: '0.5rem',
//     padding:'1rem 5px',
//     //cursor: 'pointer',
//     '&:hover' : {
//       backgroundColor: '#ebebeb'
//       //border: "1px solid " + variables.primaryColor
//     },
//     'button' : {
//       lineHeight: '0.7rem'
//     }
//   },

//   thumbnail_image : mq({
//     // paddingTop: '45%',
//     // width: '100%'
//     maxHeight: '180px',
//     margin: 'auto',
//     padding: '5px',
//     display: 'block'
//   }),
//   news_title : {
//     marginBottom: '0.2rem',
//     color: variables.redColor,
//     fontWeight: '700'
//   },
//   news_date : {
//     marginBottom: '0.2rem',
//     color: variables.redColor,
//     fontSize: '0.8rem',
//     fontWeight: '600'
//   },
//   news_des : {
//     marginBottom: '0.2rem'
//   },
//   news_content : {
//     marginTop: '1rem'
//   },
//   newsFeed_loading : {
//     textAlign: 'center',
//     'svg' : {
//       width: '100px',
//       height:'100px',
//     }
//   }
// }

export default {
  pageTiles: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    margin: '2rem -10px',
    overflow: 'hidden'
  },

  slider: {
    '.slick-prev': mq({
      left: ['0px !important', null, '-20px !important'],
    }),
    '.slick-next': mq({
      right: ['0px !important', null, '-20px !important']
    }),
    '.slick-next:before, .slick-prev:before': {
      color: 'rgb(31, 79, 156)'
    }
  },

  pageTile__tile: mq({
    flexGrow: '1',
    flexShrink: '1',
    padding: '10px',

    //for type1
    flexBasis: ['100%', null, '50%', '33.3%'],
    maxWidth: ['100%', null, '50%', '33.3%'],

    //For type2
    '&.type2': mq({
      flexBasis: ['100%', null, '50%', '33.3%'],
      maxWidth: ['100%', null, '50%', '33.3%'],
      '&.type2.useSlider': {
        flexBasis: '100%',
        maxWidth: '100%',
        height: '100%'
      },
      '.pageTile__sharkCage__details': mq({
        //padding: ['0 25px', '0 25px', '0 11px', null, '0 30px'],
        //marginTop: ['-11%', -56, -40, -36, -38],
        //marginTop: ['-11.5%', '-10.5%', '-12%', null, -56],
        // '.top-image-bar': mq({
        //   height: [70, 60]
        // }),
        '.info-title': mq({
          fontSize: '1.15rem'
        }),
        '.info-des': mq({
          //flex: '1 1 30px',
          //fontSize:'0.7rem',
          marginBottom: '0.3rem',
          overflow: 'hidden',
          //maxHeight: '49px'
        }),
        '.infoDesc': {
          //WebkitLineClamp: '2',
        },
        '.info-btn': mq({
          fontSize: '0.7rem',
          marginTop: '0.1rem'
        })

      })
    })
  }),
  tile__content: {
    '&:hover': {
      textDecoration: 'none',
      color: '#231f20',
      '.pageTile__sharkCage__bg': {
        transform: 'scale(1.05)'
      },
      '.info-wrapper': {
        backgroundColor: variables.blueColor,
        '.info-title, .info-des, .info-topic, .info-date': {
          color: 'white'
        },
        '.info-btn': {
          //backgroundColor: 'white',
          //color: variables.redColor
        }
      }
    },
    color: '#231f20',
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },

  tile__bg__wrapper: {
    //height:'80%',
    overflow: 'hidden',
    flex: '0 0 auto'
  },

  tile__bg: mq({
    paddingTop: ['56%'],
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    transition: '.5s'
  }),

  tile__details: mq({
    //padding: ['0 18px', '0 35px', '0 25px', '0 35px', '0 60px'],
    //height: '250px',
    //marginTop: ['-58px', null, '-38px', '-52px', '-56px'],
    //marginTop: ['-11.5%', -58, -37, -50, -56],
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    zIndex: '2',
    borderBottom: '4px solid ' + variables.redColor
  }),

  top__image__bar: {
    backgroundImage: 'url(/assets/tile-base-large.png)',
    height: '80px',
    backgroundPosition: 'top',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  info__wrapper: {
    backgroundColor: 'white',
    padding: '0 1.5rem',
    paddingBottom: '1rem',
    //boxShadow: '5px 5px 5px #ebebeb',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: '100%',
    position: 'relative',
    // '> div' : css({
    //      //marginTop: '0.5rem',
    //      //marginBottom: '0.5rem'
    // })
    '&::after': {
      content: '""',
      width: '100%',
      height: '77%',
      position: 'absolute',
      top: '23%',
      left: '0px',
      backgroundColor: 'transparent',
      //boxShadow: '5px 5px 5px #ebebeb'
    }
  },
  info__title: mq({
    fontWeight: '700',
    color: variables.blueColor,
    fontSize: ['24px',null,'28px'],
    lineHeight: 1,
    marginTop: '0.5rem',
    fontFamily: variables.familyHeader
  }),
  info__topic: {
    fontSize: '18px',
    color: variables.redColor,
    fontWeight: '700',
    marginTop: '1rem'
  },
  info__des: {
    marginTop: '0.7rem',
    //overflow: 'hidden',
    //maxHeight: '160px',
    //flex: '1 1 auto',
    fontSize: '18px',
    flexGrow: 1,
    minHeight: '82px',
    lineHeight: 1.3,
    color: variables.darkGrayColor
  },
  infoDesc: {
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },

  info__date: {
    fontSize: '18px',
    marginTop: '2rem',
    color:variables.darkGrayColor
  },

  newsFeed_loading: {
    textAlign: 'center',
    flex: '1 0 auto',
    'svg': {
      width: '100px',
      height: '100px',
    }
  },

  title__text: mq({
    fontSize: ['36px', null, '72px'],
    fontWeight: '900',
    color: variables.blueColor,
    fontFamily: variables.familyHeader
  }),

  news__select: {
    'select.form-control': {
      borderRight: '16px solid transparent',
    },
    '.form-control:focus' : {
      backgroundColor:'transparent',
      borderColor: 'transparent',
      boxShadow: 'none'
    },
    '.form-control' : {
      backgroundColor:'transparent',

    },
    '.ff__input' : {
      position: 'relative',
    },
    '.ff__input::after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: 'white',
      zIndex:'-1',
      top:'0px',
      border: '1px solid #ced4da',
      borderRadius: '0.25rem'
    }
  },
  news__select__container : {
    alignItems:'end'
  }

}
/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './style';
import utils from '../../utils';
import { Loading } from '../../components';
import env from '../../env';
import { useEffect, useState } from 'react';
import cmsUtils from '../../cms/utils/cmsUtils';
import { Fragment } from 'react';

export function UpComingGame(props) {

  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames('cms_item', 'fixtures', item.cssClass || item.anchorName || '');
  const gameGroup = cmsUtils.payload(item, 'GameGroup') || "cdfc";
  const [fixtures, setFixtures] = useState();
  //const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (cmsOption.isCmsEdit) {
      return;
    }

    const fetchData = async () => {
      const url = "/api/proxy/getfixture/" + gameGroup;
      const res = await fetch(env.apiBase + url);
      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
      }
      else {
        var fixtures = data.results.Result.FixtureRounds;
        const today = new Date();
        var upComingGame = null;
        var latestGame = null;
        fixtures.forEach(el => {
          const date = new Date(el.GameDateValue);

          if (upComingGame) {
            const _up = new Date(upComingGame.GameDateValue);
            if (date.getTime() <= _up.getTime()) {
              upComingGame = el;
            }
          }
          else {
            if (today.getTime() <= date.getTime()) {
              upComingGame = el;
            }
          }

          if (today.getTime() >= date.getTime()) {
            latestGame = el;
          }

        });
        setFixtures({ upComingGame: upComingGame, latestGame: latestGame });
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  if (cmsOption.isCmsEdit) {
    return (
      <div className={cssClass}
        data-cms-item-id={item.itemId}
        id={item.anchorName}>
        <pre style={{ fontSize: '12px', margin: '5px', padding: '10px', background: '#eee', border: '1px solid #ccc', marginBottom: '1rem', marginTop: '1rem' }}>
          <b> Up Coming Game : {gameGroup} </b>
        </pre>
      </div>
    )
  }

  else if (!fixtures) {
    return (
      <Loading></Loading>
    )
  }

  return (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      {
        (fixtures && fixtures.upComingGame) &&
        <Fragment>
          <h4 style={{ textAlign: 'center' }} css={style.h4__title}>
            {gameGroup === "cdfc" ? "UPCOMING SANFL MATCH" : "UPCOMING SANFLW MATCH"}
          </h4>
          <Fixture fixtureInfo={fixtures.upComingGame}></Fixture>
        </Fragment>
      }
      {
        (fixtures && fixtures.latestGame) &&
        <Fragment>
          <h4 style={{ textAlign: 'center' }} css={style.h4__title}>
            {gameGroup === "cdfc" ? "LATEST SANFL MATCH" : "LATEST SANFLW MATCH"}
          </h4>
          <Fixture fixtureInfo={fixtures.latestGame}></Fixture>
        </Fragment>
      }
    </div>
  )

}

function Fixture({ fixtureInfo }) {

  const _splictGameDate = fixtureInfo.GameDate.split(",");
  const gameDate = _splictGameDate[0].trim();
  const gameTime = _splictGameDate[1].trim();

  console.log("_gameDate", gameDate, gameTime, _splictGameDate);

  return (
    <div css={style.fixturesContainer}>
      <div css={style.fixtureContainer} className="even">

        <div css={style.fixtureInner}>

          <div css={style.fixtureLogoContainer}>
            <div css={style.fixtureLogo} style={{ backgroundImage: utils.css.bgUrlStyle(fixtureInfo.HomeTeam.TeamImage) }}></div>

            {
              fixtureInfo.ResultHomeScore &&
              <div css={style.fixtureScoreGoalMobileContainer}>
                <div css={style.fixtureScore}>
                  {fixtureInfo.ResultHomeScore}
                </div>
                <div css={style.fixtureGoal}>
                  {fixtureInfo.ResultHomeGoals + "." + fixtureInfo.ResultHomeBehinds}
                </div>
              </div>
            }

          </div>

          <div css={style.fixtureScoreContainer}>

            {
              fixtureInfo.ResultHomeScore &&
              <Fragment>
                <div css={style.fixtureScore}>
                  {fixtureInfo.ResultHomeScore}
                </div>
                <div css={style.fixtureGoal}>
                  {fixtureInfo.ResultHomeGoals + "." + fixtureInfo.ResultHomeBehinds}
                </div>
              </Fragment>
            }

          </div>

          <div css={style.fixtureRoundContainer}>
            <div css={style.fixtureRoundName}>
              {fixtureInfo.RoundName}
            </div>

            <div css={style.fixtureDate}>
              {(fixtureInfo.GameDate && fixtureInfo.GameDate.includes("12.00 AM")) ? "TBC" : gameDate}
            </div>
            <div css={style.fixtureVanue}>
              {fixtureInfo.Venue + ", "}
              <span className='game__time'>{gameTime}</span>
            </div>
          </div>

          <div css={style.fixtureScoreContainer}>
            {
              fixtureInfo.ResultAwayScore &&
              <Fragment>
                <div css={style.fixtureScore}>
                  {fixtureInfo.ResultAwayScore}
                </div>
                <div css={style.fixtureGoal}>
                  {fixtureInfo.ResultAwayGoals + "." + fixtureInfo.ResultAwayBehinds}
                </div>
              </Fragment>
            }
          </div>

          <div css={style.fixtureLogoContainer}>
            <div css={style.fixtureLogo} style={{ backgroundImage: utils.css.bgUrlStyle(fixtureInfo.AwayTeam.TeamImage) }}></div>

            {
              fixtureInfo.ResultAwayScore &&
              <div css={style.fixtureScoreGoalMobileContainer}>
                <div css={style.fixtureScore}>
                  {fixtureInfo.ResultAwayScore}
                </div>
                <div css={style.fixtureGoal}>
                  {fixtureInfo.ResultAwayGoals + "." + fixtureInfo.ResultAwayBehinds}
                </div>
              </div>
            }


          </div>

        </div>

      </div>
    </div>
  )
}
import { fb } from '../../lib/form';

export function getNewsFeedSearchModel(data) {

    const topicList = [
        { name: 'All Topics', value: '' },
        { name: 'Club Events', value: 'Club Events' },
        { name: 'Club News', value: 'Club News' },
        { name: 'Club Partners', value: 'Club Partners' },
        { name: 'Coaches Awards', value: 'Coaches Awards' },
        { name: 'Community', value: 'Community' },
        { name: 'Fun & Games', value: 'Fun & Games' },
        { name: 'Juniors', value: 'Juniors' },
        { name: 'League & Reserves', value: 'League & Reserves' },
        { name: 'Membership', value: 'Membership' },
        // { name: 'Player Announcement', value: 'Player Announcement' },
        { name: 'Player News', value: 'Player News' },
        { name: 'SANFL', value: 'SANFL' },
        { name: 'SANFLW', value: 'SANFLW' },
        { name: 'Senior League Injury Update', value: 'Senior League Injury Update' },
        { name: 'Senior League Match Report', value: 'Senior League Match Report' },
        { name: 'Senior League Team Selections', value: 'Senior League Team Selections' },
        { name: 'Wheelchair Football', value: 'Wheelchair Football' },
    ]

    const model = fb.group({
        topic : [data.topic || '', [], { label: null, type: 'select', options: topicList }],
    });
    return model;
}
import React from 'react';
import { useRouter } from '../../components';
import { FaSearch } from 'react-icons/fa';

export function SearchBar({onCloseMenu}) {
  
  const [keyword, setKeyword ] = React.useState('');
  const { navigate } = useRouter();
  
  function search(e) {
    e.preventDefault();

    if(onCloseMenu){
      onCloseMenu();
    }

    setKeyword('');
    navigate('/search-results?q=' + encodeURIComponent(keyword))
  }
  
  function onChange(e) {
    setKeyword(e.target.value);
  }

  return (
    <form className="searchBar" onSubmit={(e) => search(e)}>
      <span className='searchBar__img'>
        <FaSearch />
      </span>
      <input type="text" className="searchBar__input" placeholder="Search CDFC" value={keyword} onChange={onChange}></input>
    </form>);
}
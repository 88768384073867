/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useRouter, useGet, NotFound, ErrorPopup, MetaTag } from '../../components';
import env from '../../env';
import { useEffect, Fragment, useState } from 'react';
import utils from '../../utils';
import style from './style';
import { ViewPartial } from '../../cms/pages/partials/viewPartial';
import { FaSpinner } from "react-icons/fa";
import { NewsitemPagePartial } from '../../cms/pages/partials/newsitemPagePartial';

export function NewsView(props) {

  const { query } = useRouter();
  const newsId = query.id || '';
  const [loaded, setLoaded] = useState(false);
  console.log('newsid', newsId);

  const get = useGet();

  useEffect(() => {
    get.send(env.apiBase + `/api/news/getnewsitem/${newsId}`);
    setLoaded(false);

    // eslint-disable-next-line
  }, []);

  if (get.loading()) {
    return (
      <div css={style.loading}>
        <FaSpinner className="spinner_loading"></FaSpinner>
      </div>
    )
  }
  else if (get.hasErrors()) {
    return (
      <ErrorPopup title="Loading Error" errors={get.errors} />
    )
  }

  if (get.done() && !loaded) {
    setLoaded(true);
  }

  const dataResulst = get.response;
  console.log("dataResulst -->", dataResulst);

  return (
    <NewsPage data={dataResulst}></NewsPage>
  )
}

function NewsPage(props) {
  const newsData = (props.data.results && props.data.results.Result) ? props.data.results.Result : null;
  const newsDate = (newsData && (newsData.newsDate && newsData.newsDate !== '')) ? newsData.newsDate.split(" ")[0] : "";
  const _newsTitleTopic = newsData.newsTitle.split("||");
  const newsTitle = _newsTitleTopic[0];
  const newsContent = (newsData.content && newsData.content !== '') ? newsData.content.split('<||||>')[0] : "";
  //const topic = _newsTitleTopic.length === 2 ? _newsTitleTopic[1] : "Club Events";

  return (
    <Fragment>
      {
        newsData ?
          <Fragment>
            <MetaTag data={getMetaTag(props.data.results.Result)} />
            <div className="news__page">
              <div css={style.banner__image} className="cms_item single-banner banner-item bannerSize--standard"
                style={{ backgroundImage: utils.css.bgUrlStyle(newsData.newsPhotoURL) }}>
                <div className='banner__gradient'></div>
              </div>
              <div className="container" css={style.page__container}>
                <h1 className='news__page__title' css={style.news__page__title}> {newsTitle} </h1>
                <div css={style.news__date}> {newsDate} </div>
                {/* <div dangerouslySetInnerHTML={{ __html: newsData.content }}></div> */}
                <div dangerouslySetInnerHTML={{ __html: newsContent }}></div>
              </div>
            </div>
          </Fragment> : <NotFound></NotFound>
      }
      <NewsitemPagePartial></NewsitemPagePartial>
      <ViewPartial />
    </Fragment>
  )
}

function getMetaTag(pageData) {

  const country = "Australia";
  const siteName = env.siteName;
  const titleSuffix = env.titleSuffix;
  const _newsTitleTopic = pageData.newsTitle.split("||");
  const newsTitle = _newsTitleTopic[0];

  return {
    title: newsTitle + ' | ' + titleSuffix,
    description: pageData.newsDescription,
    keywords: '',
    heroImageUrl: utils.site.fullUrl(pageData.newsPhotoURL),
    language: 'English',
    country: country,
    siteName: siteName,
    url: utils.site.fullUrl("/newsitem/" + pageData.newsId),
    //canonical: utils.site.resourcePath(pageData.pageUrl)
  }
}
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useEffect, useState } from 'react';
import utils from '../../utils';
import cmsUtils from '../../cms/utils/cmsUtils';
import { SiteLink } from '../../components/';
import env from '../../env';
import style from './style';
import { PlayerPopUpView } from '../playerPopUpView/view';

export function TeamSelection(props){
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames('cms_item', 'team__selection', item.cssClass || item.anchorName || '');
  const teamType = cmsUtils.payload(item, 'TeamType') || "";

  //const [trigger, setTrigger] = useState("");
  const [openStatus, setOpenStatus] = useState({});
  const [result, setResult] = useState();
  const [openPopup, setOpenPopup] = useState(false);
  const [popupItem, setPopupItem] = useState();

  useEffect(() => {

    if (cmsOption.isCmsEdit){
      return;
    }

    const fetchData = async () => {
      //const teamType = trigger;
      const url = teamType === "" ? "/api/team/getTeamselectionall" : "/api/team/getteamselection/" + teamType;
      const res = await fetch(env.apiBase + url);
      const data = await res.json();

      console.log("data ---->>>> ", data, teamType);

      if(teamType === ""){
        setResult(data.results);
        setOpenStatus({sanfl : true, reserve : true, u18 : true, u16 : true});
      }
      else{
        setResult({...result, [teamType] : data.results.Result});
        setOpenStatus({...openStatus, [teamType] : true});
      }
    }

    fetchData();
    
    // eslint-disable-next-line
  }, []);

  if (cmsOption.isCmsEdit){
    return (
      <div className={cssClass}
        data-cms-item-id={item.itemId} 
        id={item.anchorName}>
        <pre style={{ fontSize: '12px', margin: '5px', padding: '10px', background: '#eee', border: '1px solid #ccc' }}>
          <b> Team Selection </b>
        </pre>
        </div>
    )
  }

  return(
    
    <Fragment>

  {
      openPopup &&
      <PlayerPopUpView popupItem = {popupItem} setOpenPopup = {setOpenPopup} ></PlayerPopUpView>
    }
     
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      {
        (teamType === "" || teamType === "sanfl") &&
        <div className = "__sanfl" css={style.team__selection__container}>
        {/* <Button onClick = {() => {
          
          if(result && result.sanfl){
            setOpenStatus({...openStatus, sanfl : !openStatus.sanfl});
          }
          // else{
          //   setTrigger("sanfl");
          // }
        }}> {(openStatus.sanfl && openStatus.sanfl === true) ? "CLOSE LEAGUE TEAM SELECTIONS" : "VIEW LEAGUE TEAM SELECTIONS"}  </Button> */}
        {
          (result && result.sanfl && openStatus.sanfl === true) &&
          <Fragment>
            <TeamSelectionItem title = "LEAGUE TEAM SELECTIONS" teamType = {teamType} item = {result.sanfl} setPopupItem = {setPopupItem} setOpenPopup = {setOpenPopup}></TeamSelectionItem>
            <div style={{marginTop: '0.5rem'}}>
            {/* <Button onClick={() => {
              setOpenStatus({...openStatus, sanfl : !openStatus.sanfl});
            }} > CLOSE LEAGUE TEAM SELECTIONS </Button> */}
          </div>
          </Fragment>
        }
        </div>
      }
      {
        (teamType === "" || teamType === "reserve") &&
        <div className = "__reserve" css={style.team__selection__container}>
        {/* <Button onClick = {() => {
          
          if(result && result.reserve){
            setOpenStatus({...openStatus, reserve : !openStatus.reserve});
          }
          // else{
          //   setTrigger("reserve");
          // }
        }}> {(openStatus.reserve && openStatus.reserve === true) ? "CLOSE RESERVE TEAM SELECTIONS" : "VIEW RESERVE TEAM SELECTIONS"}  </Button> */}
        {
          (result && result.reserve && openStatus.reserve === true) &&
          <Fragment>
            <TeamSelectionItem title = "RESERVE TEAM SELECTIONS" teamType = {teamType} item = {result.reserve} setPopupItem = {setPopupItem} setOpenPopup = {setOpenPopup}></TeamSelectionItem>
            <div style={{marginTop: '0.5rem'}}>
            {/* <Button onClick={() => {
              setOpenStatus({...openStatus, reserve : !openStatus.reserve});
            }} > CLOSE RESERVE TEAM SELECTIONS </Button> */}
          </div>
          </Fragment>
        }
        </div>
      }
      {
        (teamType === "" || teamType === "u18") &&
        <div className = "__u18" css={style.team__selection__container}>
        {/* <Button onClick = {() => {
          
          if(result && result.u18){
            setOpenStatus({...openStatus, u18 : !openStatus.u18});
          }
          // else{
          //   setTrigger("u18");
          // }
        }}> {(openStatus.u18 && openStatus.u18 === true) ? "CLOSE U18 TEAM SELECTIONS" : "VIEW U18 TEAM SELECTIONS"}  </Button> */}
        {
          (result && result.u18 && openStatus.u18 === true) &&
          <Fragment>
            <TeamSelectionItem title = "U18 TEAM SELECTIONS" teamType = {teamType} item = {result.u18} setPopupItem = {setPopupItem} setOpenPopup = {setOpenPopup}></TeamSelectionItem>
            <div style={{marginTop: '0.5rem'}}>
            {/* <Button onClick={() => {
              setOpenStatus({...openStatus, u18 : !openStatus.u18});
            }} > CLOSE U18 TEAM SELECTIONS </Button> */}
          </div>
          </Fragment>
        }
        </div>
      }
      {
        (teamType === "" || teamType === "u16") &&
        <div className = "__u16" css={style.team__selection__container}>
        {/* <Button onClick = {() => {
          
          if(result && result.u16){
            setOpenStatus({...openStatus, u16 : !openStatus.u16});
          }
          // else{
          //   setTrigger("u16");
          // }
        }}> {(openStatus.u16 && openStatus.u16 === true) ? "CLOSE U16 TEAM SELECTIONS" : "VIEW U16 TEAM SELECTIONS"}  </Button> */}
        {
          (result && result.u16 && openStatus.u16 === true) &&
          <Fragment>
            <TeamSelectionItem title = "U16 TEAM SELECTIONS" teamType = {teamType} item = {result.u16} setPopupItem = {setPopupItem} setOpenPopup = {setOpenPopup}></TeamSelectionItem>
            <div style={{marginTop: '0.5rem'}}>
            {/* <Button onClick={() => {
              setOpenStatus({...openStatus, u16 : !openStatus.u16});
            }} > CLOSE U16 TEAM SELECTIONS </Button> */}
          </div>
          </Fragment>
        }
        </div>
      }
      {
        (teamType === "women") &&
        <div className = "__women" css={style.team__selection__container}>
        {/* <Button onClick = {() => {
          
          if(result && result.women){
            setOpenStatus({...openStatus, women : !openStatus.women});
          }
          // else{
          //   setTrigger("women");
          // }
        }}> {(openStatus.women && openStatus.women === true) ? "CLOSE WOMEN'S TEAM SELECTIONS" : "VIEW WOMEN'S TEAM SELECTIONS"}  </Button> */}
        {
          (result && result.women && openStatus.women === true) &&
          <Fragment>
            <TeamSelectionItem title = "WOMEN'S TEAM SELECTIONS" teamType = {teamType} item = {result.women} setPopupItem = {setPopupItem} setOpenPopup = {setOpenPopup}></TeamSelectionItem>
            <div style={{marginTop: '0.5rem'}}>
            {/* <Button onClick={() => {
              setOpenStatus({...openStatus, women : !openStatus.women});
            }} > CLOSE WOMEN'S TEAM SELECTIONS </Button> */}
          </div>
          </Fragment>
        }
        </div>
      }
      {
        (teamType === "womendevelopment") &&
        <div className = "__womendevelopment" css={style.team__selection__container}>
        {
          (result && result.womendevelopment && openStatus.womendevelopment === true) &&
          <Fragment>
            <TeamSelectionItem title = "WOMEN'S DEVELOPMENT TEAM SELECTIONS" teamType = {teamType} item = {result.womendevelopment} setPopupItem = {setPopupItem} setOpenPopup = {setOpenPopup}></TeamSelectionItem>
            <div style={{marginTop: '0.5rem'}}></div>
          </Fragment>
        }
        </div>
      }
      {
        (teamType === "leagueCTOTW") &&
        <div className = "__leagueCTOTW" css={style.team__selection__container}>
        {
          (result && result.leagueCTOTW && openStatus.leagueCTOTW === true) &&
          <Fragment>
            <TeamSelectionItem title = "LEAGUE CLUB TEAM OF THE WEEK" teamType = {teamType} item = {result.leagueCTOTW} setPopupItem = {setPopupItem} setOpenPopup = {setOpenPopup}></TeamSelectionItem>
            <div style={{marginTop: '0.5rem'}}></div>
          </Fragment>
        }
        </div>
      }
      {
        (teamType === "reserveCTOTW") &&
        <div className = "__reserveCTOTW" css={style.team__selection__container}>
        {
          (result && result.reserveCTOTW && openStatus.reserveCTOTW === true) &&
          <Fragment>
            <TeamSelectionItem title = "RESERVE CLUB TEAM OF THE WEEK" teamType = {teamType} item = {result.reserveCTOTW} setPopupItem = {setPopupItem} setOpenPopup = {setOpenPopup}></TeamSelectionItem>
            <div style={{marginTop: '0.5rem'}}></div>
          </Fragment>
        }
        </div>
      }
      {
        (teamType === "u18CTOTW") &&
        <div className = "__u18CTOTW" css={style.team__selection__container}>
        {
          (result && result.u18CTOTW && openStatus.u18CTOTW === true) &&
          <Fragment>
            <TeamSelectionItem title = "U18 CLUB TEAM OF THE WEEK" teamType = {teamType} item = {result.u18CTOTW} setPopupItem = {setPopupItem} setOpenPopup = {setOpenPopup}></TeamSelectionItem>
            <div style={{marginTop: '0.5rem'}}></div>
          </Fragment>
        }
        </div>
      }
      {
        (teamType === "u16CTOTW") &&
        <div className = "__u16CTOTW" css={style.team__selection__container}>
        {
          (result && result.u16CTOTW && openStatus.u16CTOTW === true) &&
          <Fragment>
            <TeamSelectionItem title = "U16 CLUB TEAM OF THE WEEK" teamType = {teamType} item = {result.u16CTOTW} setPopupItem = {setPopupItem} setOpenPopup = {setOpenPopup}></TeamSelectionItem>
            <div style={{marginTop: '0.5rem'}}></div>
          </Fragment>
        }
        </div>
      }
      {
        (teamType === "womenCTOTW") &&
        <div className = "__womenCTOTW" css={style.team__selection__container}>
        {
          (result && result.womenCTOTW && openStatus.womenCTOTW === true) &&
          <Fragment>
            <TeamSelectionItem title = "WOMEN CLUB TEAM OF THE WEEK" teamType = {teamType} item = {result.womenCTOTW} setPopupItem = {setPopupItem} setOpenPopup = {setOpenPopup}></TeamSelectionItem>
            <div style={{marginTop: '0.5rem'}}></div>
          </Fragment>
        }
        </div>
      }
      {
        (teamType === "wdCTOTW") &&
        <div className = "__wdCTOTW" css={style.team__selection__container}>
        {
          (result && result.wdCTOTW && openStatus.wdCTOTW === true) &&
          <Fragment>
            <TeamSelectionItem title = "WOMEN DEVELOPMENT CLUB TEAM OF THE WEEK" teamType = {teamType} item = {result.wdCTOTW} setPopupItem = {setPopupItem} setOpenPopup = {setOpenPopup}></TeamSelectionItem>
            <div style={{marginTop: '0.5rem'}}></div>
          </Fragment>
        }
        </div>
      }
    </div>
    </Fragment>
  )
}

function TeamSelectionItem(props){

  const item = props.item;
  const teamType = props.teamType;
  const title = props.title;

  


  const _players2 = (() => {
    const tmp = {ruck : [], interchange : [], in : [], out : []};
    item.teamSetPlayer.forEach(el => {
      if(el.position.toLowerCase() === "ruck"){
        tmp.ruck.push(el);
      }
      else if(el.position.toLowerCase() === "interchange"){
        tmp.interchange.push(el);
      }
      else if(el.position.toLowerCase() === "in"){
        tmp.in.push(el);
      }
      else if(el.position.toLowerCase() === "out"){
        tmp.out.push(el);
      }
    });
    return tmp;
  });

  const players2 = _players2();

  const getPlayerByPosition = ((position) => {
    for(let i in item.teamSetPlayer){
      if(item.teamSetPlayer[i].position.toLowerCase() === position){
        return(
          <SiteLink css={style.player1__name} className={position} to = "" onClick={(e)=> {
            openPlayerPopUp(e, item.teamSetPlayer[i]);
          }}>
            {item.teamSetPlayer[i].playerName}
          </SiteLink>
        )
      }
    }
  });
  
  const openPlayerPopUp = ((e, item) => {
    e.stopPropagation();
    e.preventDefault();
    
    if(teamType !== "u18" && teamType !== "u16"){
      props.setPopupItem(item);
      props.setOpenPopup(true);
      document.body.style.overflow = "hidden";
    }
  });

  return(
    <Fragment>
      <div css={style.typeTitle} style={{marginTop: '0.5rem', marginBottom: '-1rem'}}>{title}</div>
      <div css={style.title} style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}>{item.teamSet.gameTitle}</div>
      <div style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}>{item.teamSet.gameDesc}</div>
      <div css = {style.team__selection} className={teamType}>
        {
          (item.teamSetPlayer && item.teamSetPlayer.length > 0) &&
          <table>
          <tbody>
            <tr>
              <td> { getPlayerByPosition("forward_pocket_left") } </td>
              <td> { getPlayerByPosition("full_forward") } </td>
              <td> { getPlayerByPosition("forward_pocket_right") } </td>
            </tr>
            <tr>
              <td> { getPlayerByPosition("half_forward_left") } </td>
              <td> { getPlayerByPosition("centre_half_forward") } </td>
              <td> { getPlayerByPosition("half_forward_right") } </td>
            </tr>
            <tr>
              <td> { getPlayerByPosition("wing_left") } </td>
              <td> { getPlayerByPosition("centre") } </td>
              <td> { getPlayerByPosition("wing_right") } </td>
            </tr>
            <tr>
              <td> { getPlayerByPosition("half_back_left") } </td>
              <td> { getPlayerByPosition("centre_half_back") } </td>
              <td> { getPlayerByPosition("half_back_right") } </td>
            </tr>
            <tr>
              <td> { getPlayerByPosition("back_pocket_left") } </td>
              <td> { getPlayerByPosition("full_back") } </td>
              <td> { getPlayerByPosition("back_pocket_right") } </td>
            </tr>
          </tbody>
        </table>  
        }
      </div> 

      <div>
        <span css={style.player2__title}> Ruck - </span>
        {
          players2.ruck.map((item, index) => 
            <SiteLink to = "" css={style.player2__name} key={index} className={ (index + 1) === players2.ruck.length ? 'last' : ''} onClick={(e)=> {
              openPlayerPopUp(e, item);
            }}>
              {item.playerName + ((index + 1) === players2.ruck.length ? '' :  ',')}
            </SiteLink>
          )
        }
      </div>
      <div>
        <span css={style.player2__title}>Interchange - </span>
        {
          players2.interchange.map((item, index) => 
            <SiteLink to = "/" css={style.player2__name} key={index} className={ (index + 1) === players2.interchange.length ? 'last' : ''} onClick={(e)=> {
              openPlayerPopUp(e, item);
            }}>
              {item.playerName + ((index + 1) === players2.interchange.length ? '' :  ',')}
            </SiteLink>
          )
        }
      </div>
      <div>
        <span css={style.player2__title}> In - </span>
        {
          players2.in.map((item, index) => 
            <SiteLink to = "" css={style.player2__name} key={index} className={ (index + 1) === players2.in.length ? 'last' : ''} onClick={(e)=> {
              openPlayerPopUp(e, item);
            }}>
              {item.playerName + ((index + 1) === players2.in.length ? '' :  ',')}
            </SiteLink>
          )
        }
      </div>
      <div>
        <span css={style.player2__title}> Out - </span>
        {
          players2.out.length > 0 ?
          players2.out.map((item, index) => 
            <SiteLink to = "" css={style.player2__name} key={index} className={ (index + 1) === players2.out.length ? 'last' : ''} onClick={(e)=> {
              openPlayerPopUp(e, item);
            }}>
              {item.playerName + ((index + 1) === players2.out.length ? '' :  ',')}
            </SiteLink>
          ) : "N/A"
        }
      </div>

    </Fragment>
  )
}
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState, Fragment } from 'react';
import style from './style';
import utils from '../../utils';
//import cmsUtils from '../../cms/utils/cmsUtils';
import { usePost, useGet, Loading, ErrorPopup } from '../../components/';
import env from '../../env';
//import { FaSpinner } from "react-icons/fa";
import { getSearchFormModel } from './searchFormModel';
import { useForm } from '../../form';
//import { SiteLink } from '../../lib/router';

export function HistorySearch(props){

  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames('cms_item', 'historySearch', item.cssClass || item.anchorName || '');
  const get = useGet();
  
  useEffect(() => {
    get.send(env.apiBase + '/api/proxy/getsearchfilter');
    
    // eslint-disable-next-line
  }, []);

  if (cmsOption.isCmsEdit){
    return(
      <div className={cssClass}
        data-cms-item-id={item.itemId} 
        id={item.anchorName}>
        <pre style={{ fontSize: '12px', margin: '5px', padding: '10px', background: '#eee', border: '1px solid #ccc' }}>
          <b> CDFC History Search Item </b>
        </pre>
      </div>
    )
  }

  if (get.loading()) {
    return <Loading />
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />
  }
  const data = get.response;
  return(
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      {
        (data && data.results.Result) && 
        <HistoryForm filterData={data.results.Result}></HistoryForm>
      }
    </div>
  )
}

function HistoryForm(props){

  const filterData = props.filterData;
  const lsStoreInfo = utils.site.getStoreInfo();

  if(lsStoreInfo.checkBackupResult === true){
    filterData.PlayerName = lsStoreInfo.filter.PlayerName;
    filterData.Year = lsStoreInfo.filter.Year;
    filterData.yearInAdvancedFilter = lsStoreInfo.filter.yearInAdvancedFilter;
    filterData.CompetitionType = lsStoreInfo.filter.CompetitionType;
    filterData.Round = lsStoreInfo.filter.Round;
    filterData.Opponent = lsStoreInfo.filter.Opponent;
    filterData.GameType = lsStoreInfo.filter.GameType;
    filterData.UseAdvancedFilter = lsStoreInfo.filter.UseAdvancedFilter;
  }

  const model = getSearchFormModel(filterData);
  const historySearchForm = useForm(model, {usePlaceholder : true, onControlChanged: orderItemChanged});
  const post = usePost();
  const [sendingStatus, setSendingStatus] = useState(null);
  //const [openSearchResult, setOpenSearchResult] = useState("ready");
  //const [openAdvance, setOpenAdvance] = useState(false);

  const [openSearchResult, setOpenSearchResult] = useState(() => {
    if(lsStoreInfo.checkBackupResult === true){
      return "done";
    }
    return "ready";
  });  
  
  const [openAdvance, setOpenAdvance] = useState(() => {
    if(filterData.UseAdvancedFilter === true){
      return true;
    }
    return false;
  });
  
  const render = (name) => historySearchForm.renderControl(name, null);

  function orderItemChanged(e, control){
    
    if (control && control.name === 'year'){
      historySearchForm.findControl("yearInAdvancedFilter").value = control.value;
    }
    
    if (control && control.name === 'yearInAdvancedFilter'){
      historySearchForm.findControl("year").value = control.value;
    }

    if (control && control.name === 'menOrWomen'){
      historySearchForm.findControl("competitionType").value = "";
      historySearchForm.findControl("competitionTypeForWomen").value = "";
    }

  }

  function onSubmit(e){
    e.preventDefault();
    historySearchForm.validateForm(e, () => { 
      
      //console.log("ddddd1");
      setOpenSearchResult("loading");
      
      let _value = historySearchForm.getValue();
      
      if(_value.menOrWomen === 'women'){
        _value.competitionType = _value.competitionTypeForWomen;
      }
      
      let dateStrings = _value._date.split("-");
      let formatChangedDate = dateStrings.length > 1 ? dateStrings[2] + "/" + dateStrings[1] + "/" + dateStrings[0] : '';
      const value = { ..._value, date : formatChangedDate };
      
      console.log("dsdsfsdfsdfs", value);
      post.send(env.apiBase + "/api/proxy/historysearch", value);
      
      setSendingStatus('pending');
      //console.log(value);
    });
  }

  // useEffect(() => {
  //   if (post.done() && sendingStatus === 'pending'){
  //     console.log("result", post.response);
  //     setSendingStatus(null);
  //   }
  //   // eslint-disable-next-line
  // }, [post]);

  if (post.done() && sendingStatus === 'pending'){ 
    //lsStoreInfo.checkBackupResult = false;
    setSendingStatus(null);
    setOpenSearchResult("done");
  }

  return(
    <Fragment>
    <form css={style.historySearchForm} className="historySearchForm" onSubmit = {onSubmit}>
      <div>{ openAdvance }</div>
      <div className = "form__top" >
      <div className="row">
        <div className="col-12 col-sm-3">
          <label className="label"> Player Name </label>
          {render('playerName')}
        </div>
        <div className="col-12 col-sm-2">
          {render('year')}
        </div>
        <div className="col-12 col-sm-2">
          {render('menOrWomen')}
        </div>
        <div className="col-12 col-sm-5">
          <button css = {style.advancedSearchBtn} className="btn btn-primary" type="button" onClick={() => {
            let _value = historySearchForm.findControl("useAdvancedFilter").value;
            historySearchForm.findControl("useAdvancedFilter").value = !_value;
            setOpenAdvance(!openAdvance);
          }}> 
            Advanced Search Games +
          </button>
        </div>
      </div>
      </div>
      {
        openAdvance &&
        <div className = "form__bottom">
          <div className="row">
            
            <div className="col-12 col-sm-4">
              {render('yearInAdvancedFilter')}
            </div>

            <div className="col-12 col-sm-4">
              { 
                historySearchForm.findControl("menOrWomen").value !== 'women' ? 
                render('competitionType') : render('competitionTypeForWomen')
              }
            </div>

            <div className="col-12 col-sm-4">
              {render('round')}
            </div>

            <div className="col-12 col-sm-4">
              <label className="label"> Date </label>
              {render('_date')}
            </div>

            <div className="col-12 col-sm-4">
              {render('opponent')}
            </div>

            <div className="col-12 col-sm-4">
              {render('gameType')}
            </div>

          </div>
        </div>
      }
      <div css={style.submitBtnContainer}>
        <button className="btn btn-primary" type="submit" > 
            SEARCH
        </button>
      </div> 
    </form>

    {
      openSearchResult === 'done' ? <SearchResult searchResult = {post.response} lsStoreInfo = {lsStoreInfo} ></SearchResult> :
      (openSearchResult === 'loading' && <Loading></Loading> )
    }

    </Fragment>
  )
}

function SearchResult(props){

  const _result = props.searchResult.results ? props.searchResult.results.Result : {
    Filter : null,
    Games : null,
    Players : null
  };

  const menOrWomen = props.searchResult.results.MenOrWomen;
  const searchResult = props.lsStoreInfo.checkBackupResult === true ? { Filter : props.lsStoreInfo.filter, Games : props.lsStoreInfo.games, Players : props.lsStoreInfo.players} : _result;
  //const games = searchResult.Games;

  const games = searchResult.Games ? (
    menOrWomen === 'women' ?
      searchResult.Games.filter(x => x.CompetitionCode.toLowerCase() === 'women') :
      searchResult.Games.filter(x => x.CompetitionCode.toLowerCase() !== 'women')) :
    searchResult.Games;

  //const players = searchResult.Players;
  const players = searchResult.Players ? (
    menOrWomen === 'women' ?
      searchResult.Players.filter(x => x.PlayerType === 'women') :
      searchResult.Players.filter(x => x.PlayerType !== 'women')
  ) : searchResult.Players;

  const _games ={league : [], women : []};
  
  if(games && games.length > 0){
    for(var i in games){
      if(games[i].CompetitionCode.toLowerCase() === 'women'){
        _games.women.push(games[i]);
      }
      else{
        _games.league.push(games[i]);
      }
    }
  }

  console.log("games___", _games);

  return(
    <div className = "search__result __container" css = {style.searchResultContainer}>
      {
        games && 
        <div className="games__result__container">
          <h3> Results </h3>
          {
            games.length > 0 ?
            <div className = "games__result"> 
              {
                _games.league.map((item, index) => 
                <div css = {style.item} key = {index} className="game__item"> 
                  <div>
                    <a href = {`/history/search?type=game&id=${item.GameId}`} onClick={() => {   
                        utils.site.setupLsForSearch(searchResult);
                    }}>
                      { utils.date.formatDDMMYYYY(item.GameDate) }
                    </a>
                    <span css = {style.gameItem__title2} >
                      { item.OpponentTeam + ' (' + item.Result + ')' }
                    </span>
                  </div>
                  <div>
                    <span>
                      { item.CompetitionName + "  " + item.GameTypeName + " " + ((item.Round && item.Round !== '') ? item.Round + ', ' : '')}
                    </span>
                    <span>
                      { "Venue: " + item.OvalName }
                    </span>
                  </div>
                </div>)
              }
              {
                (_games.women && _games.women.length > 0) &&
                <Fragment>
                  <h3> Women Results </h3>
                  {
                    _games.women.map((item, index) => 
                    <div css = {style.item} key = {index} className="game__item"> 
                      <div>
                        <a href = {`/history/search?type=game&id=${item.GameId}`} onClick={() => {   
                            utils.site.setupLsForSearch(searchResult);
                        }}>
                          { utils.date.formatDDMMYYYY(item.GameDate) }
                        </a>
                        <span css = {style.gameItem__title2} >
                          { item.OpponentTeam + ' (' + item.Result + ')' }
                        </span>
                      </div>
                      <div>
                        <span>
                          { item.CompetitionName + "  " + item.GameTypeName + " " + ((item.Round && item.Round !== '') ? item.Round + ', ' : '')}
                        </span>
                        <span>
                          { "Venue: " + item.OvalName }
                        </span>
                      </div>
                    </div>)
                  }

                </Fragment> 
              }

            </div> : "No result in games"
          } 
        </div>
      }
      {
        players && 
        <div className="players__result__container">
          <h3> Results </h3>
          {
            players.length > 0 ?
            <div className = "players__result">
              {
                players.map((item, index) =>
                <div css = {style.item} key = {index} className="player__item" >
                <div>
                  {/* <SiteLink to = {`/test`}>
                    { item.Surname + ', ' + item.OtherNames}
                  </SiteLink> */}
                  <a href = {`/history/search?type=player&id=${item.PlayerId}`} onClick={() => {
                    utils.site.setupLsForSearch(searchResult);
                  }}>
                    {/* { item.Surname + ', ' + item.OtherNames} */}
                    { item.Surname + (item.OtherNames && item.OtherNames !== '' ? ', ' + item.OtherNames : '') }
                  </a>
                  {
                    (item.Era && item.Era !== '') &&
                    <span style={{marginLeft:'0.3rem'}}>
                     { "(" + item.Era + ")" }
                    </span>
                  }
                </div>
                
                <div>
                    <span>
                      {"Games: " + item.GamesPlayed}
                    </span>
                    <span style={{marginLeft: '1rem'}}>
                      {"Goals: " + item.Goals}
                    </span>
                    <span css={style.year__played}>
                      {"Years played: " + item.YearsPlayedSpan}
                    </span>
                  </div>

                </div>)
              }
            </div> : "No result in players"
          } 
        </div>
      }
    </div>
  )
}
/** @jsx jsx */
import { jsx } from '@emotion/core'
import cmsUtils from '../../cmsUtils';
import { TileList } from 'site/tileList/tileList'
import { getTileListStyle } from 'site/tileList/tileListStyle';

import { PageTile } from './pageTile/pageTile'

export function PageTiles(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  //console.log(cmsOption)
  //console.log('item', item);
  const useSlider = cmsUtils.payload(item, 'UseSlider') === 'slider';
  const pages = getPages(cmsUtils.payload(item, 'PageObjects'))

  const tiles = pages.map((page, index) => <PageTile key={index} page={page} useSlider={useSlider} disableLink={cmsOption.isCmsEdit} />)
  
  return (
    // <TileList tiles={tiles} style={getTileListStyle()} useSlider={useSlider} />
    <TileList tiles={tiles} style={getTileListStyle()} />
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}
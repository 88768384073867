import { variables, mq } from '../../cssInJs'

export default {
  
  h4__title : {
    fontFamily: variables.familyHeader,
    color:variables.blueColor
  },
  
  fixtureButtonContainer: {
    padding: '2px'
  },
  fixtureButton: {
    backgroundColor: '#d9d9d9',
    //marginLeft: '0.5rem',
    //marginRight: '0.5rem',
    textAlign: 'center',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    cursor: 'pointer',
    color: "#585858",
    fontWeight: "600",
    '&.selected': {
      backgroundColor: variables.redColor,
      color: 'white'
    }
  },
  fixturesContainer: {
    marginTop: '5px',
    marginBottom: '1rem',
    paddingLeft: '2px',
    paddingRight: '2px'
  },
  fixtureContainer: mq({
    marginLeft: '0px',
    marginRight: '0px',
    paddingTop: ['1rem',null,null,'0.5rem'],
    paddingBottom: ['1rem',null,null,'0.5rem'],
    background:`white`,
    boxShadow: '3px 5px 10px rgba(0,0,0,0.3)'
    //background: linear-gradient($color 80%, 90%, $color2 100%),
    //background:`linear-gradient(${variables.blueColor} 60%, rgba(0,0,0,0.9))`,
    //background:`linear-gradient(${variables.blueColor} 80%, 90%, rgba(0,0,0,0.7) 100%)`,
    // "&.even": {
    //   backgroundColor: '#ebebeb'
    // },
    // "&.odd": {
    //   backgroundColor: 'white'
    // },
  }),
  fixtureWrapper: {
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem'
  },

  fixtureRoundName: {
    fontWeight: 700,
    textAlign: 'center',
    color:variables.blueColor
  },

  fixtureBar: {
    marginLeft: '0.5rem',
    marginRight: '0.5rem'
  },

  fixtureDate: {
    //fontWeight: 600,
    textAlign: 'center',
    fontSize:'0.8rem',
    color:variables.darkGrayColor
  },

  fixtureVanue: mq({
    fontSize: '0.8rem',
    textAlign: 'center',
    color:variables.darkGrayColor,
    'span.game__time' : mq({
      display:['block', null, null, 'inline']
    })
  }),

  hr: {
    marginTop: '0.3rem',
    marginBottom: '0.3rem'
  },

  fixtureInner: {
    display: 'flex',
  },

  fixtureLogoContainer: mq({
    //display:'flex',
    flex: ['1 0 30%',null,null, '1 0 20%'],
    maxWidth: ['30%', null, null, '20%']
  }),

  fixtureLogo: mq({
    width: '80px',
    height: '80px',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    margin: 'auto',
  }),
  teamName: mq({
    fontWeight: 600,
    marginLeft: ['0rem', null, '1rem'],
    position: ['static', null, 'absolute'],
    transform: ['unset', null, 'translateY(-50%)'],
    top: '50%',
    textAlign: 'center',
    display: ['block', null, 'inline'],
    lineHeight: 1,
    marginTop: ['0.3rem', null, '0rem']
  }),

  fixtureScoreGoalMobileContainer:mq({
    display:['block',null,null,'none']
  }),

  fixtureScoreContainer: mq({
    flex: '1 0 20%',
    maxWidth: '20%',
    //display:'flex',
    display : ['none',null,null,'flex'],
    justifyContent: 'center',
    flexDirection: 'column',
  }),

  fixtureScore: {
    textAlign: 'center',
    color:variables.redColor
  },

  fixtureGoal: {
    textAlign: 'center',
    color:variables.darkGrayColor
  },

  fixtureRoundContainer: mq({
    //flex: '1 0 20%',
    //maxWidth: '20%',
    flex: ['1 0 40%',null,null, '1 0 20%'],
    maxWidth: ['40%', null, null, '20%'],
    color:'white',
    display:'flex',
    flexDirection:'column',
    justifyContent:'center'
  }),

  score: mq({
    fontWeight: 600,
    position: ['static', null, 'absolute'],
    transform: ['unset', null, 'translateY(-50%)'],
    top: '50%',
    left: "50%",
    textAlign: 'center',
    display: ['block', null, 'inline'],
    lineHeight: 1,
    marginTop: ['0.3rem', null, '0rem']
  })
}